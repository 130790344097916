import {React, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createOrder } from '../../actions/orderAction'
import { useNavigate } from 'react-router-dom'
import { ClearCart } from '../../actions/cartAction'

const CheckoutSection = () => {
    const redirect = useNavigate();
    const dispatch = useDispatch();
    const [privacy, setPrivacy] = useState(false)

    const [BillingInfo, setBillingInfo ] = useState({
        firstName: '',
        lastName: '',
        companyName: '',
        email: '',
        phone: '',
        streetAddress: '',
        streetAddress2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        paymentMethod: 'cash',
        note: ''
    })

    const [ShippingInfo, setShippingInfo ] = useState({
        SfirstName: '',
        SlastName: '',
        SstreetAddress: '',
        SstreetAddress2: '',
        Scity: '',
        Sstate: '',
        Szip: '',
        Scountry: '',
        SpaymentMethod: 'cash',
    })

    const handleOrder = () => {
        dispatch(createOrder({
            'billingInfo': BillingInfo,
            'shippingInfo': ShippingInfo,
            'orderItems': cartItems,
            'itemsPrice': getCartSubTotal(),
            'shippingPrice': 1,
            'paymentMethod': 'cash',
            'totalPrice': getCartSubTotal() + 1,
        }))
        dispatch(ClearCart());
        redirect('/thank-you')
    }

    const handleBillingInfo = (e) => {
        setBillingInfo({
            ...BillingInfo,
            [e.target.name]: e.target.value
        })
    }

    const handleShippingInfo = (e) => {
        setShippingInfo({
            ...ShippingInfo,
            [e.target.name]: e.target.value
        })
    }



    const Cart = useSelector(state => state.Cart)
    const { cartItems, shippingInfo } = Cart

    const getCartSubTotal = () => {
        return cartItems.reduce((price, item) => price + item.price * item.qty, 0)
    }

    return (
        <>
            <section className="shopping-checkout-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="checkout-page-coupon-wrap">
                                <div className="coupon-accordion" id="CouponAccordion">
                                    <div className="card">
                                        <h3>
                                            <i className="fa fa-info-circle"></i>
                                            Have a Coupon?
                                            <a href="#/" data-bs-toggle="collapse" data-bs-target="#couponaccordion">Click here to enter your code</a>
                                        </h3>
                                        <div id="couponaccordion" className="collapse" data-bs-parent="#CouponAccordion">
                                            <div className="card-body">
                                                <div className="apply-coupon-wrap">
                                                    <p>If you have a coupon code, please apply it below.</p>
                                                    <form action="#" method="post">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" placeholder="Coupon code" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <button type="button" className="btn-coupon">Apply coupon</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="checkout-billing-details-wrap">
                                <h2 className="title">Billing details</h2>
                                <div className="billing-form-wrap">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="f_name">First name <abbr className="required" title="required">*</abbr></label>
                                                    <input id="f_name" onChange={handleBillingInfo} type="text" name='firstName' className="form-control" required />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="l_name">Last name <abbr className="required" title="required">*</abbr></label>
                                                    <input id="l_name" onChange={handleBillingInfo} type="text" className="form-control" name='lastName' />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="com_name">Company name (optional)</label>
                                                    <input id="com_name" onChange={handleBillingInfo} name='companyName' type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="country">Country <abbr className="required" title="required">*</abbr></label>
                                                    <select id="country" onChange={handleBillingInfo} name='country' className="form-control">
                                                        <option>Bangladesh</option>
                                                        <option>Afghanistan</option>
                                                        <option>Albania</option>
                                                        <option>Algeria</option>
                                                        <option>Armenia</option>
                                                        <option>India</option>
                                                        <option>Pakistan</option>
                                                        <option>England</option>
                                                        <option>London</option>
                                                        <option>London</option>
                                                        <option>China</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="street-address">Street address <abbr className="required" title="required">*</abbr></label>
                                                    <input id="street-address" onChange={handleBillingInfo} name='streetAddress' type="text" className="form-control" placeholder="House number and street name" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="street-address2" className="visually-hidden">Street address 2 <abbr className="required" title="required">*</abbr></label>
                                                    <input id="street-address2" onChange={handleBillingInfo} name='streetAddress2' type="text" className="form-control" placeholder="Apartment, suite, unit etc. (optional)" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="town">Town / City <abbr className="required" title="required">*</abbr></label>
                                                    <input id="town" name='city' onChange={handleBillingInfo} type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="district">State <abbr className="required" title="required">*</abbr></label>
                                                    <select id="district" onChange={handleBillingInfo} name='state' className="form-control">
                                                        <option>Afghanistan</option>
                                                        <option>Albania</option>
                                                        <option>Algeria</option>
                                                        <option>Armenia</option>
                                                        <option>India</option>
                                                        <option>Pakistan</option>
                                                        <option>England</option>
                                                        <option>London</option>
                                                        <option>London</option>
                                                        <option>China</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="pz-code">Postcode / ZIP (optional)</label>
                                                    <input id="pz-code" name='zip' onChange={handleBillingInfo} type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="phone">Phone (optional)</label>
                                                    <input id="phone" name='phone' onChange={handleBillingInfo} type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group" data-margin-bottom="30">
                                                    <label htmlFor="email">Email address <abbr className="required" title="required">*</abbr></label>
                                                    <input id="email" name='email' onChange={handleBillingInfo} type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div id="CheckoutBillingAccordion2" className="col-md-12">
                                                <div className="checkout-box" data-margin-bottom="25" data-bs-toggle="collapse" data-bs-target="#CheckoutTwo" role="toolbar">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input visually-hidden" id="ship-to-different-address" />
                                                        <label className="custom-control-label" htmlFor="ship-to-different-address">Ship to a different address?</label>
                                                    </div>
                                                </div>
                                                <div id="CheckoutTwo" className="collapse" data-margin-top="30" data-bs-parent="#CheckoutBillingAccordion2">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="f_name2">First name <abbr className="required" title="required">*</abbr></label>
                                                                <input name='SfirstName' onChange={handleShippingInfo} id="f_name2" type="text" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="l_name2">Last name <abbr className="required" title="required">*</abbr></label>
                                                                <input id="l_name2" name='SlastName' onChange={handleShippingInfo} type="text" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label htmlFor="com_name2">Company name (optional)</label>
                                                                <input id="com_name2" name='Scompany' onChange={handleShippingInfo} type="text" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label htmlFor="country2">Country <abbr className="required" title="required">*</abbr></label>
                                                                <select id="country2" name='Scountry' onChange={handleShippingInfo}className="form-control">
                                                                    <option>Bangladesh</option>
                                                                    <option>Afghanistan</option>
                                                                    <option>Albania</option>
                                                                    <option>Algeria</option>
                                                                    <option>Armenia</option>
                                                                    <option>India</option>
                                                                    <option>Pakistan</option>
                                                                    <option>England</option>
                                                                    <option>London</option>
                                                                    <option>London</option>
                                                                    <option>China</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label htmlFor="street-address2-3">Street address <abbr className="required" title="required">*</abbr></label>
                                                                <input id="street-address2-3" type="text" name='SstreetAddress' onChange={handleShippingInfo}className="form-control" placeholder="House number and street name" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="street-address2-2" className="visually-hidden">Street address 2 <abbr className="required" title="required">*</abbr></label>
                                                                <input id="street-address2-2" type="text" name='SstreetAddress2' onChange={handleShippingInfo}className="form-control" placeholder="Apartment, suite, unit etc. (optional)" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label htmlFor="town3">Town / City <abbr className="required" title="required">*</abbr></label>
                                                                <input id="town3" name='Scity' onChange={handleShippingInfo} type="text" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label htmlFor="district2">State <abbr className="required" title="required">*</abbr></label>
                                                                <select id="district2" name='Sstate' onChange={handleShippingInfo}className="form-control">
                                                                    <option>Afghanistan</option>
                                                                    <option>Albania</option>
                                                                    <option>Algeria</option>
                                                                    <option>Armenia</option>
                                                                    <option>India</option>
                                                                    <option>Pakistan</option>
                                                                    <option>England</option>
                                                                    <option>London</option>
                                                                    <option>London</option>
                                                                    <option>China</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label htmlFor="pz-code2">Postcode / ZIP (optional)</label>
                                                                <input id="pz-code2" name='Szip' onChange={handleShippingInfo} type="text" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group mb--0">
                                                    <label htmlFor="order-notes">Order notes (optional)</label>
                                                    <textarea id="order-notes" name='note' onChange={handleBillingInfo} className="form-control" placeholder="Notes about your order, e.g. special notes for delivery."></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="checkout-order-details-wrap">
                                <div className="order-details-table-wrap table-responsive">
                                    <h2 className="title mb-25">Your order</h2>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="product-name">Product</th>
                                                <th className="product-total">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-body">
                                            {
                                                cartItems.map((item, i) => {
                                                    return (
                                                        <tr key={i} className="cart-item">
                                                            <td className="product-name">{item.name} <span className="product-quantity">× {item.qty}</span></td>
                                                            <td className="product-total">${item.price}</td>
                                                        </tr>
                                                    )
                                                }
                                                )
                                            }
                                        </tbody>
                                        <tfoot className="table-foot">
                                            <tr className="cart-subtotal">
                                                <th>Subtotal</th>
                                                <td>${getCartSubTotal()}</td>
                                            </tr>
                                            <tr className="shipping">
                                                <th>Shipping</th>
                                                <td>Flat rate: $1.00</td>
                                            </tr>
                                            <tr className="order-total">
                                                <th>Total </th>
                                                <td>${getCartSubTotal() + 1}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div className="shop-payment-method">
                                        <div id="PaymentMethodAccordion">
                                            {/* <div className="card">
                                                <div className="card-header" id="check_payments">
                                                    <h5 className="title" data-bs-toggle="collapse" data-bs-target="#itemOne" aria-controls="itemOne">Direct bank transfer</h5>
                                                </div>
                                                <div id="itemOne" className="collapse show" aria-labelledby="check_payments" data-bs-parent="#PaymentMethodAccordion">
                                                    <div className="card-body">
                                                        <p>Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="check_payments2">
                                                    <h5 className="title" data-bs-toggle="collapse" data-bs-target="#itemTwo" aria-controls="itemTwo" >Check payments</h5>
                                                </div>
                                                <div id="itemTwo" className="collapse" aria-labelledby="check_payments2" data-bs-parent="#PaymentMethodAccordion">
                                                    <div className="card-body">
                                                        <p>Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="card">
                                                <div className="card-header" id="check_payments3">
                                                    <h5 className="title" data-bs-toggle="collapse" data-bs-target="#itemThree" aria-controls="itemTwo" >Cash on delivery</h5>
                                                </div>
                                                <div id="itemThree" className="collapse" aria-labelledby="check_payments3" data-bs-parent="#PaymentMethodAccordion">
                                                    <div className="card-body">
                                                        <p>Pay with cash upon delivery.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="card">
                                                <div className="card-header" id="check_payments4">
                                                    <h5 className="title" data-bs-toggle="collapse" data-bs-target="#itemFour" aria-controls="itemTwo" >PayPal Express Checkout</h5>
                                                </div>
                                                <div id="itemFour" className="collapse" aria-labelledby="check_payments4" data-bs-parent="#PaymentMethodAccordion">
                                                    <div className="card-body">
                                                        <p>Pay via PayPal; you can pay with your credit card if you don’t have a PayPal account.</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                        <p className="p-text">Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our <a href="#/">privacy policy.</a></p>
                                        <div className="agree-policy">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" id='privacy' checked={privacy}className="custom-control-input visually-hidden"  onChange={()=>{
                                                    setPrivacy(!privacy)
                                                } }/>
                                                <label htmlFor="privacy" className="custom-control-label">I have read and agree to the website terms and conditions <span className="required">*</span></label>
                                            </div>
                                        </div>
                                        <button onClick={handleOrder} disabled={!privacy} className={
                                            privacy ? "btn-place-order" : "btn-place-order disabled"
                                        }>Place order</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CheckoutSection