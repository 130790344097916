import React from 'react'
import { Link } from 'react-router-dom'

const AboutArea = () => {
  return (
    <>
        <section className="about-area about-inner-area bg-color-f6">
                <div className="container">
                    <div className="about-item about-item-style2 position-relative">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-thumb text-center">
                                    <img src="assets/img/about/2.webp" width="529" height="429" alt="HasTech" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-content">
                                    <div className="section-title shape-left">
                                        <h5 className="sub-title">Best PRODUCT</h5>
                                        <h2 className="title">Best Pet Food</h2>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidid ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate</p>
                                    <p className="text-color-theme">Velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidata non proident, sunt in culpa qui officia deserun</p>
                                    <Link className="btn-theme" to="/shop">Shop Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  )
}

export default AboutArea