import React from 'react'

const SearchAside = () => {
    return (
        <>
            <aside className="aside-search-box-wrapper offcanvas offcanvas-top" tabIndex="-1" id="AsideOffcanvasSearch" aria-labelledby="offcanvasTopLabel">
                <div className="offcanvas-header">
                    <h5 className="d-none" id="offcanvasTopLabel">Aside Search</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"><i className="pe-7s-close"></i></button>
                </div>
                <div className="offcanvas-body">
                    <div className="container pt--0 pb--0">
                        <div className="search-box-form-wrap">
                            <div className="search-note">
                                <p>Start typing and press Enter to search</p>
                            </div>
                            <form action="#" method="post">
                                <div className="search-form position-relative">
                                    <label htmlFor="search-input" className="visually-hidden">Search</label>
                                    <input id="search-input" type="search" className="form-control" placeholder="Search entire store…" />
                                    <button className="search-button"><i className="fa fa-search"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    )
}

export default SearchAside