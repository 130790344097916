import React from 'react'
import ProductBannerItem from '../Product/ProductBannerItem'

const ProductBanner = () => {
    return (
        <>
            <section className="product-area product-banner-area">
                <div className="container">
                    <div className="row">
                        <ProductBannerItem/>
                        <ProductBannerItem/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductBanner