import {React, useEffect, useMemo, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { clearModal } from '../../actions/modalAction'
import { AddtoCart } from '../../actions/cartAction'
const ProductModal = () => {
    const dispatch = useDispatch()
    const Modal = useSelector(state => state.Modal)
    const data = Modal.data ? Modal.data : [];
    const [quantity, setQuantity] = useState(1);

    const closeModal = () => {
        dispatch(clearModal())
    }

    useMemo(() => {
        setQuantity(1)
    }, [])
    

    const handleMinus = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    }

    const handlePlus = () => {
        setQuantity(quantity + 1);
    }

    const handleInput = (e) => {
        setQuantity(e.target.value);
    }

    return (
        <>
            <aside className="product-cart-view-modal modal fade" id="action-QuickViewModal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="product-quick-view-content">
                                <button type="button" className="btn-close" onClick={closeModal} data-bs-dismiss="modal">
                                    <span className="pe-7s-close"></span>
                                </button>
                                <div className="container pt--0 pb--0">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="product-single-thumb">
                                                {data.images &&
                                                <img src={data.images[0]} width="544" height="560" alt="HasTech" />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="product-single-info">
                                                <h3 className="main-title">{data.name}</h3>
                                                <div className="prices">
                                                    <span className="price">${data.price}</span>
                                                </div>
                                                <div className="rating-box-wrap">
                                                    <div className="rating-box">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                    <div className="review-status">
                                                        <a href="/">(5 Customer Review)</a>
                                                    </div>
                                                </div>
                                                <p>{data.description}</p>
                                                <div className="product-single-meta">
                                                    <ul>
                                                        <li><span>SKU:</span> {data.skuNumber}</li>
                                                        <li><span>Category:</span>
                                                            <a href="shop.html"> { data.category && data.category.name }</a>
                                                        </li>
                                                        {
                                                            data.tags && data.tags.map((tag, index) => {
                                                                return (
                                                                    <li key={index}><span>Tags:</span>
                                                                        <Link to="/shop">{tag.name}</Link>
                                                                    </li>
                                                                )
                                                            }
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="product-quick-action">
                                                    <div className="qty-wrap">
                                                    <div className="pro-qty">
                                                        <i className="fa fa-minus" onClick={handleMinus} ></i>
                                                        <input type="number" onChange={handleInput} title="Quantity" value={quantity} />
                                                        <i className="fa fa-plus" onClick={handlePlus}></i>
                                                    </div>
                                                    </div>
                                                    <button type="button" className="btn-product-cart" onClick={()=> {dispatch(AddtoCart(data, quantity)) }} data-bs-toggle="modal" data-bs-target="#action-CartAddModal">
                                                        Add To Cart
                                                    </button>
                                                    <button type="button" className="btn-product-wishlist" data-bs-toggle="modal" data-bs-target="#action-WishlistModal">
                                                        <i className="pe-7s-like"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    )
}

export default ProductModal