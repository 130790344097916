import React from 'react'
import TeamItem from './TeamItem'

const Team = () => {
  return (
    <>
        <section className="team-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title shape-center text-center">
              <h5 className="sub-title">Our Team</h5>
              <h2 className="title">Team Member</h2>
            </div>
          </div>
        </div>
        <div className="row">
        <TeamItem/>
        <TeamItem/>
        <TeamItem/>
        <TeamItem/>
        </div>
      </div>
    </section>
    </>
  )
}

export default Team