import { React, useRef } from 'react'
import { Link } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import { AddtoCart } from '../../actions/cartAction'
import { setModal } from '../../actions/modalAction'

const ProductItem = (props) => {

    const modal = useRef(null)

    const { product } = props;

    const dispatch = useDispatch()

    const handleAddToCart = () => {
        dispatch(AddtoCart(product, 1))
    }

    const handleQuickView = (product) => {
        modal.current.click()
        dispatch(setModal(product))
    }

    return (
        <>
            <div className="product-row-item" >
                <div className="product-item">
                    <div className="product-thumb">
                        <Link to={`/products/${product.slug}`}>
                            {
                                product.images ? <img src={product.images[0]} alt="product" /> : <img src="assets/images/product/product-1.jpg" alt="product" />
                            }
                        </Link>
                    </div>
                    <div className="product-info">
                        <h4 className="title"><Link to={`/products/${product.slug}`}>{product.name}</Link></h4>
                        <div className="rating-product">
                            <div className="rating-box">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                            </div>
                        </div>
                        <div className="prices">
                            <span className="price">${product.price}</span>
                        </div>
                    </div>
                    <div className="product-action">
                        <button type="button" className="btn-product-wishlist" data-bs-toggle="modal" data-bs-target="#action-WishlistModal">
                            <i className="pe-7s-like"></i>
                        </button>
                        <div className="product-action-links">
                            <button type="button" className="btn-product-cart" onClick={handleAddToCart} data-bs-toggle="offcanvas" data-bs-target="#AsideOffcanvasCart" aria-controls="AsideOffcanvasCart">
                                <i className="pe-7s-shopbag"></i>
                            </button>
                            <button type="button" className="btn-product-quick-view" onClick={() => {
                                handleQuickView(product)
                            }} >
                                <i className="pe-7s-look"></i>
                            </button>
                            <button className='d-none' ref={modal} data-bs-toggle="modal" data-bs-target="#action-QuickViewModal"></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductItem