import {React, useEffect} from 'react'
import FeatureArea from '../components/About/FeatureArea'
import PageHeadre from '../components/Product/PageHeadre'

const Login = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      })
    return (
        <>
            <PageHeadre title="Login"/>
            <section className="account-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="login-form-content">
                                <form action="#">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label for="username">Username or email address <span className="required">*</span></label>
                                                <input id="username" className="form-control" type="email" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label for="password">Password <span className="required">*</span></label>
                                                <input id="password" className="form-control" type="password" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <a className="btn-login" href="account.html">Login</a>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group account-info-group mb--0">
                                                <div className="rememberme-account">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                        <label className="form-check-label" for="defaultCheck1">Remember me</label>
                                                    </div>
                                                </div>
                                                <a className="lost-password" href="account-register.html">Lost your password?</a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FeatureArea/>
        </>
    )
}

export default Login