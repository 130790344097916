import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
const { RemoveCartItem, MinusCartItemQty, PlusCartItemQty } = require('../../actions/cartAction')
const Cart = () => {
    const dispatch = useDispatch()
    const cart = useSelector(state => state.Cart)
    const { cartItems } = cart
    const getCartSubTotal = () => {
        return cartItems.reduce((price, item) => (item.price * item.qty) + price, 0)
    }
    return (
        <>
            <section className="shopping-cart-area">
                <div className="container">
                    {

                        cartItems.length === 0 ? (
                            <div className='text-center'>
                                <div className="text-center">
                                    <img style={{ padding: '30px' }} src="/assets/img/icons/empty-cart.svg" alt="empty-cart" />
                                    <h3>Your cart is empty</h3>
                                    <p>Looks like you haven’t added any items to your cart yet.</p>
                                </div>
                                <Link to="/shop" className="btn-theme mt-4 shop-now">Shop</Link>
                            </div>

                        ) : (
                            <>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="shopping-cart-form table-responsive">
                                            <form action="#" method="post">
                                                <table className="table text-center">

                                                    <thead>
                                                        <tr>
                                                            <th className="product-remove">&nbsp;</th>
                                                            <th className="product-thumbnail">Product Image</th>
                                                            <th className="product-name">Product</th>
                                                            <th className="product-price">Price</th>
                                                            <th className="product-quantity">Quantity</th>
                                                            <th className="product-subtotal">Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            cartItems.map(item => (

                                                                <tr className="tbody-item">
                                                                    <td className="product-remove">
                                                                        <div className="remove cursor-pointer" onClick={() => {
                                                                            dispatch(RemoveCartItem(item.product))
                                                                        }} >×</div>
                                                                    </td>
                                                                    <td className="product-thumbnail">
                                                                        <div className="thumb">
                                                                            <Link to="/product-item">
                                                                                <img src={item.image
                                                                                    ? item.image
                                                                                    : 'https://via.placeholder.com/75x75'} width="75" height="75" alt="HasTech" />
                                                                            </Link>
                                                                        </div>
                                                                    </td>
                                                                    <td className="product-name">
                                                                        <Link className="title" to="/product-item">{item.name}</Link>
                                                                    </td>
                                                                    <td className="product-price">
                                                                        <span className="price">${item.price}</span>
                                                                    </td>
                                                                    <td className="product-quantity product-center ">
                                                                            <div className="qty-wrap">
                                                                                <div className="pro-quan">
                                                                                    <i className="fa fa-minus" onClick={() => {
                                                                                        console.log(item.product)
                                                                                        dispatch(MinusCartItemQty(item.product))

                                                                                    }} ></i>
                                                                                    <input type="number" title="Quantity" value={item.qty} />
                                                                                    <i className="fa fa-plus" onClick={
                                                                                        () => {
                                                                                            dispatch(PlusCartItemQty(item.product))
                                                                                        }
                                                                                    }></i>
                                                                                </div>
                                                                            </div>
                                                                    </td>
                                                                    <td className="product-subtotal">
                                                                        <span className="price">${item.price * item.qty}</span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <div className="coupon-wrap">
                                            <h4 className="title">Coupon</h4>
                                            <p className="desc">Enter your coupon code if you have one.</p>
                                            <input type="text" className="form-control" placeholder="Coupon code" />
                                            <button type="button" className="btn-coupon">Apply coupon</button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="cart-totals-wrap">
                                            <h2 className="title">Cart totals</h2>
                                            <table>
                                                <tbody>
                                                    <tr className="cart-subtotal">
                                                        <th>Subtotal</th>
                                                        <td>
                                                            <span className="amount">${getCartSubTotal()}</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="text-end">
                                                <Link to="/checkout" className="checkout-button">Proceed to checkout</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>

            </section>
        </>
    )
}

export default Cart