import React from 'react'
import ProductItem from '../Product/ProductItem'

const Products = (props) => {

    const { data, sectionTitle, sectionSubtitle } = props;

    return (
        <>
            <section className="product-area product-default-area">
                <div className="container pt--0">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title mb-45 mb-sm-20 shape-center text-center">
                                <h5 className="sub-title">{sectionTitle}</h5>
                                <h2 className="title">{sectionSubtitle}</h2>
                            </div>
                        </div>
                    </div>
                    <div className='product-row'>{
                        data.map((product) => (
                            <ProductItem product={product} key={product._id} />
                        ))
                    }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Products