import {React, useEffect} from 'react'
import FeatureArea from '../components/About/FeatureArea'
import PageHeadre from '../components/Product/PageHeadre'

const Register = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      })
    return (
        <>
            <PageHeadre title="Sign Up"/>
            <section className="account-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="register-form-content">
                                <form action="#">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label for="username">Username <span className="required">*</span></label>
                                                <input id="username" className="form-control" type="email" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label for="email">Email address <span className="required">*</span></label>
                                                <input id="email" className="form-control" type="email" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label for="password">Password <span className="required">*</span></label>
                                                <input id="password" className="form-control" type="password" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group mb--0">
                                                <a className="btn-register" href="shop.html">Register</a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FeatureArea/>
        </>
    )
}

export default Register