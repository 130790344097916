import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './views/Home';
import Shop from './views/Shop';
import Header from './components/Header';
import Footer from './components/Footer';
import SingleProduct from './views/SingleProduct';
import About from './views/About';
import Page404 from './views/Page404';
import Login from './views/Login';
import Register from './views/Register';
import Contact from './views/Contact';
import CartAside from './components/Cart/CartAside';
import SearchAside from './components/Search/SearchAside';
import ProductModal from './components/Product/ProductModal';
import Cart from './components/Cart/Cart';
import Checkout from './views/Checkout';
import ProductDetail from './components/Product/ProductDetail';
import Thanks from './views/Thanks';
import MenuAside from './components/Home/MenuAside';
import CategoryShop from './views/CategoryShop';

function App() {
  return (
    <>
      <Router>  
        <Header />
        <main className="main-content">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/products" element={<Shop />} />
            <Route path='/shop/:slug' element={<CategoryShop/>} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/products/:slug" element={<ProductDetail />} /> 
            <Route path="/product-item" element={<SingleProduct />} />
            <Route path="/cart" element={<Cart/>} />
            <Route path="/login" element={<Login/>} />
            <Route path="/register" element={<Register/>} />
            <Route path="/contact" element={<Contact/> } />
            <Route path="/checkout" element={<Checkout/> } />
            <Route path="/thank-you" element={<Thanks/> } />
            <Route path="*" element={<Page404/>} />
          </Routes>
        </main>
        <Footer />
        <MenuAside/>
        <ProductModal/>
        <CartAside/>
        <SearchAside/>
        
      </Router>
    </>
  );
}

export default App;
