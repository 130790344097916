import {React, useEffect} from 'react'
import PageHeadre from '../components/Product/PageHeadre'
import ProductItem from '../components/Product/ProductItem'
import {useSelector} from 'react-redux'
const Shop = () => {
  const Product = useSelector(state => state.Product)
  useEffect(() => {
    window.scrollTo(0, 0);
  })
  return (
    <>
    <PageHeadre title="Shop"/>
    <section className="product-area">
      <div className="container">
        <div className="row justify-content-between">
          {/* <div className="col-12">
            <div className="shop-top-bar">
              <div className="shop-top-left">
                <p className="pagination-line"><a href="shop.html">12</a> Product Found of <a href="shop.html">30</a></p>
              </div>
              <div className="shop-top-center">
                <nav className="product-nav">
                  <div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                    <button className="nav-link active" id="nav-grid-tab" data-bs-toggle="tab" data-bs-target="#nav-grid" type="button" role="tab" aria-controls="nav-grid" aria-selected="true"><i className="fa fa-th"></i></button>
                    <button className="nav-link" id="nav-list-tab" data-bs-toggle="tab" data-bs-target="#nav-list" type="button" role="tab" aria-controls="nav-list" aria-selected="false"><i className="fa fa-list"></i></button>
                    <button className="nav-link" id="nav-grid2-tab" data-bs-toggle="tab" data-bs-target="#nav-grid2" type="button" role="tab" aria-controls="nav-grid2" aria-selected="false"><i className="fa fa-th-large"></i></button>
                  </div>
                </nav>
              </div>
              <div className="shop-top-right">
                <div className="shop-sort" style={{width : "101%"}} >
                  <span>Sort By :</span>
                  <select className="form-select" aria-label="Sort select example">
                    <option>Default</option>
                    <option>Popularity</option>
                    <option>Average Rating</option>
                    <option>Newsness</option>
                    <option>Price Low to High</option>
                  </select>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-grid" role="tabpanel" aria-labelledby="nav-grid-tab">
                    <div className="row">
                      {
                        Product.products.map((product) => (
                          <ProductItem product={product} key={product._id} />
                        ))
                      }

                      {/* <div className="col-12">
                        <div className="pagination-items pagination-items-style1">
                          <ul className="pagination justify-content-center mb--0">
                            <li><a className="active" href="shop-four-columns.html">1</a></li>
                            <li><a href="shop-four-columns.html">2</a></li>
                            <li><a href="shop-four-columns.html">3</a></li>
                            <li><a href="shop-four-columns.html" className="icon"><i className="fa fa-angle-right"></i></a></li>
                          </ul>                    
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Shop