import React from 'react'
import CheckoutSection from '../components/Cart/CheckoutSection'
import PageHeadre from '../components/Product/PageHeadre'

const Checkout = () => {
  return (
    <>
        <PageHeadre title="Checkout" />
        <CheckoutSection/>
    </>
  )
}

export default Checkout