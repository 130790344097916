import React from 'react'
import TestimonialItem from './TestimonialItem'

const Testimonial = () => {
  return (
    <>
        <section className="testimonial-area bg-color-f2 position-relative z-index-1">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title shape-center text-center">
              <h5 className="sub-title">testimonial</h5>
              <h2 className="title">What Client Says</h2>
            </div>
          </div>
        </div>
        <div className="row">
            <TestimonialItem/>
            <TestimonialItem/>
            <TestimonialItem/>
        </div>
      </div>
    </section>
    </>
  )
}

export default Testimonial