import React from 'react'
import { Link } from 'react-router-dom'
// import { Link } from 'react-router-dom'

const CategoryItem = (props) => {
    return (
        <>
            <div className="popular-Cat-Item">
                <div className="product-category-item">
                    <div className="thumb">
                        <Link to={props.link}><img src={props.img} alt="Categories" /></Link>
                    </div>
                    <div className="content">
                        <h3 className="title" style={
                            props.text ? {fontSize: '1rem'} : {fontSize: ''}
                        }><Link to={props.link}>{props.name}</Link></h3>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoryItem