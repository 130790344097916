import {combineReducers} from 'redux';
import Product from './productReducers';
import Cart from './cartReducers';
import Modal from './modalReducers';
import Category from './categoryReducers';
import Order from './orderReducers';
const rootReducer = combineReducers({
    Product,
    Cart,
    Modal,
    Category,
    Order
});

export default rootReducer;