import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { RemoveCartItem } from '../../actions/cartAction'

const CartAside = () => {
    const dispatch = useDispatch()
    const cart = useSelector(state => state.Cart)
    const { cartItems } = cart

    const getCartSubTotal = () => {
        return cartItems.reduce((price, item) => (item.price * item.qty) + price, 0)
    }

    return (
        <>
            <aside className="aside-cart-wrapper offcanvas offcanvas-end" tabIndex="-1" id="AsideOffcanvasCart" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <h1 className="d-none" id="offcanvasRightLabel">Shopping Cart</h1>
                    <button className="btn-aside-cart-close" data-bs-dismiss="offcanvas" aria-label="Close">Shopping Cart <i className="fa fa-chevron-right"></i></button>
                </div>
                <div className="offcanvas-body">
                    <ul className="aside-cart-product-list">
                        {
                            cartItems.length === 0 ? (
                                <div className="text-center">
                                    <img style={{ padding: '30px' }} src="/assets/img/icons/empty-cart.svg" alt="empty-cart" />
                                    <h3>Your cart is empty</h3>
                                    <p>Looks like you haven’t added any items to your cart yet.</p>
                                    <div data-bs-dismiss="offcanvas">
                                        <Link to="/shop" className="btn-total">Shop Now</Link>
                                    </div>
                                </div>
                            ) : (
                                cartItems.map((item) => (
                                    <li className="aside-product-list-item" key={item.product}>
                                        <div onClick={() => { dispatch(RemoveCartItem(item.product)) }} className="remove">×</div>
                                        <a href="/">
                                            <img src={item.image} width="90" height="110" alt="HasTech" />
                                            <span className="product-title">{item.name}</span>
                                        </a>
                                        <span className="product-price">{item.qty} × ${item.price}</span>
                                    </li>
                                ))
                            )
                        }
                    </ul>
                    {
                        cartItems.length > 0 && (
                            <>
                                <p className="cart-total"><span>Subtotal:</span><span className="amount">${getCartSubTotal()}</span></p>
                                <div data-bs-dismiss="offcanvas" >
                                    <Link className="btn-total" to="/cart" >View cart</Link>
                                </div>
                                <div data-bs-dismiss="offcanvas" className='mt-3' >
                                    <Link className="btn-total" to="/checkout">Checkout</Link>
                                </div>
                            </>
                        )
                    }
                </div>
            </aside>
        </>
    )
}

export default CartAside