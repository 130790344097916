import { React, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { AddtoCart } from '../../actions/cartAction';
import ProductSuggestions from './ProductSuggestions';

const ProductDetail = () => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    const { products } = useSelector(state => state.Product);
    const { cartItems } = useSelector(state => state.Cart);

    const [quantity, setQuantity] = useState(1);
    const product = products.find(item => item.slug === slug);

    useEffect(() => {
        window.scrollTo(0, 0);
        cartItems.map(item => {
            if (item.product === product._id) {
                setQuantity(item.qty);
            }
        }
        )

    }, []);


    const handleMinus = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    }

    const handlePlus = () => {
        setQuantity(quantity + 1);
    }

    const handleInput = (e) => {
        setQuantity(e.target.value);
    }

    return (
        <>
            <section className="product-area product-single-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="product-single-item">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="product-single-thumb">
                                            <div className="swiper single-product-thumb single-product-thumb-slider">
                                                <div className="swiper-wrapper">
                                                    <Swiper
                                                        autoplay={{
                                                            delay: 3000,
                                                            disableOnInteraction: false
                                                        }}
                                                        pagination={{
                                                            clickable: true,
                                                            dynamicBullets: true,
                                                        }}
                                                        modules={[Pagination, Autoplay]}
                                                    >
                                                        {
                                                            product.images.map((item, index) => (
                                                                <SwiperSlide key={index}>
                                                                    <div className="swiper-slide">
                                                                        <a className="lightbox-image" data-fancybox="gallery" href={item}>
                                                                            <img src={item} width="570" height="675" alt="HasTech" />
                                                                        </a>
                                                                    </div>
                                                                </SwiperSlide>
                                                            ))
                                                        }
                                                    </Swiper>
                                                </div>
                                            </div>
                                            
                                        </div>

                                    </div>
                                    <div className="col-lg-6">

                                        <div className="product-single-info">
                                            <h3 className="main-title">{product.name}</h3>
                                            <div className="prices">
                                                <span className="price">${product.price}</span>
                                            </div>
                                            <div className="rating-box-wrap">
                                                <div className="rating-box">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div>
                                                <div className="review-status">
                                                    <a href="/">{"(5 Customer Review)"}</a>
                                                </div>
                                            </div>
                                            <p>{product.description}</p>
                                            <div className="product-single-meta">
                                                <ul>
                                                    <li><span>SKU:</span> {product.skuNumber}</li>
                                                    <li><span>Category:</span>
                                                        <Link to="/"> {product.category.name}</Link>
                                                    </li>
                                                    {
                                                        product.tags.length !== 0 && product.tags.map((item, index) => (
                                                            <li key={index}><span>Tags:</span>
                                                                <Link to="/"> {item}</Link>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                            <div className="product-quick-action">
                                                <div className="qty-wrap">
                                                    <div className="pro-qty">
                                                        <i className="fa fa-minus" onClick={handleMinus} ></i>
                                                        <input type="number" onChange={handleInput} title="Quantity" value={quantity} />
                                                        <i className="fa fa-plus" onClick={handlePlus}></i>
                                                    </div>
                                                </div>
                                                <button type="button" className="btn-product-cart" data-bs-toggle="offcanvas" data-bs-target="#AsideOffcanvasCart" aria-controls="AsideOffcanvasCart" onClick={() => {
                                                    dispatch(AddtoCart(product, quantity));
                                                }} >
                                                    Add To Cart
                                                </button>
                                                <button type="button" className="btn-product-wishlist" data-bs-toggle="modal" data-bs-target="#action-WishlistModal">
                                                    <i className="pe-7s-like"></i>
                                                </button>
                                            </div>
                                            <div className="product-review-tabs-content">
                                                <ul className="nav product-tab-nav" id="ReviewTab" role="tablist">
                                                    <li role="presentation">
                                                        <a className="active" id="information-tab" data-bs-toggle="pill" href="#information" role="tab" aria-controls="information" aria-selected="true">Information</a>
                                                    </li>
                                                    <li role="presentation">
                                                        <a id="description-tab" data-bs-toggle="pill" href="#description" role="tab" aria-controls="description" aria-selected="false">Description</a>
                                                    </li>
                                                    <li role="presentation">
                                                        <a id="reviews-tab" data-bs-toggle="pill" href="#reviews" role="tab" aria-controls="reviews" aria-selected="false">Reviews (05)</a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content product-tab-content" id="ReviewTabContent">
                                                    <div className="tab-pane fade show active" id="information" role="tabpanel" aria-labelledby="information-tab">
                                                        <div className="product-information">
                                                            <p>{product.information}</p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="description" role="tabpanel" aria-labelledby="description-tab">
                                                        <div className="product-description">
                                                            <p>{product.description}</p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                                                        <div className="product-review-content">
                                                            {
                                                                product.reviews.map((item, index) => (
                                                                    <div key={index} className="single-product-review">
                                                                        <div className="comment-author">
                                                                            <div className="comment-thumb">
                                                                                <img src="assets/img/shop/avatar.webp" width="60" height="60" alt="HasTech" />
                                                                            </div>
                                                                            <div className="comment-content">
                                                                                <div className="rating-box">
                                                                                    <i className="fa fa-star"></i>
                                                                                    <i className="fa fa-star"></i>
                                                                                    <i className="fa fa-star"></i>
                                                                                    <i className="fa fa-star"></i>
                                                                                    <i className="fa fa-star"></i>
                                                                                </div>
                                                                                <h4 className="title"><span>{item.name}</span> - {item.date}</h4>
                                                                                <p className="comment">{item.comment}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                            <div className="comment-form-content">
                                                                <h4 className="title collapsed" data-bs-toggle="collapse" data-bs-target="#comment-widgetId-1">Add Reviwe</h4>
                                                                <div id="comment-widgetId-1" className="collapse collapse-body">
                                                                    <div className="review-comment-form">
                                                                        <form action="#">
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="ReviewComment" className="form-label">Your review *</label>
                                                                                        <textarea id="ReviewComment" className="form-control"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="Reviewname" className="form-label">Name *</label>
                                                                                        <input id="Reviewname" className="form-control" type="text" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="Reviewemail" className="form-label">Email *</label>
                                                                                        <input id="Reviewemail" className="form-control" type="email" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <div className="form-group mb--0">
                                                                                        <button type="submit">Submit</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <ProductSuggestions/> */}
        </>
    )
}

export default ProductDetail