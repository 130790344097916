import React from 'react'
import AboutArea from '../components/About/AboutArea'
import ProductBanner from '../components/Home/ProductBanner'
import PageHeadre from '../components/Product/PageHeadre'
import Team from '../components/Team/Team'
import Testimonial from '../components/Testimonial/Testimonial'

const About = () => {
    return (
        <>
            <PageHeadre title="About" />
            <AboutArea/>
            <Team />
            <Testimonial />
            <ProductBanner />
        </>
    )
}

export default About