
export const setModal = (modal) => {
    return {
        type: 'SET_MODAL',
        payload: modal,
    };
};

export const clearModal = () => {
    return {
        type: 'CLEAR_MODAL',
    };
}

