import React from 'react'
import { Link } from 'react-router-dom'

const Thanks = () => {
    return (
        <>

            <div className='container'>
                <div className="text-center d-flex flex-column align-items-center">
                    <h1>Thank you for your purchase!</h1>
                    <img style={{
                        width: '300px',
                    }} src="/assets/img/thankyou.png" alt="thank you gif" />
                    <Link to="/shop" className='btn-theme mt-4 shop-now' >Continue Shopping</Link>
                </div>
            </div>
        </>
    )
}

export default Thanks