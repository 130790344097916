import axios from 'axios'
const host = process.env.REACT_APP_API_HOST || 'http://localhost:5000'

export const createOrder = (order) => async (dispatch, getState) => {
    try {
        dispatch({
            type: 'ORDER_CREATE_REQUEST',
        })

        const token = localStorage.getItem('token');

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': `${token}`,
            },
        }

        const { data } = await axios.post(`${host}/orders`, order, config)

        dispatch({
            type: 'ORDER_CREATE_SUCCESS',
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: 'ORDER_CREATE_FAIL',
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

