import {React, useEffect} from 'react'
import { Link } from 'react-router-dom'

const Page404 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  })
  return (
    <>
      <section className="page-not-found-area">
        <div className="container pt--0 pb--0">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-6">
              <div className="page-not-found-wrap">
                <div className="page-not-found-content">
                  <h3 className="not-found-text">404</h3>
                  <h3 className="title">Page Cannot Be Found!</h3>
                  <p className="desc">Seems like nothing was found at this location. Try something else or you can go back to the homepage following the button below!</p>
                  <Link className="btn-theme-border" to="/">Back to home</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Page404