import React from 'react'
import CategoryItem from '../Category/CategoryItem'

const Category = (props) => {

    const { data } = props;
    

    return (
        <>
        <section className="product-area product-category-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title shape-center text-center">
                                <h5 className="sub-title">TRENDING CATEGORIES</h5>
                                <h2 className="title">Shop By Pet Category</h2>
                            </div>
                        </div>
                    </div>
                    <div className="Category-item">
                        {
                            data.categories.slice(0, 5).map((category) => (
                                <CategoryItem img={category.photo} link={`/shop/${category.slug}`} name={category.name} key={category._id} />
                            ))
                        }
                    </div>
                </div>
            </section>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title shape-center text-center">
                            <h5 className="sub-title">Popular CATEGORIES</h5>
                            <h2 className="title">Shop By Popular Category</h2>
                        </div>
                    </div>
                </div>
                <div className="d-grid">
                    <div className='popular-Cat'>
                        {
                            data.categories.slice(5).map((category) => (
                                <CategoryItem text={true} img={category.photo} link={`/shop/${category.slug}`} name={category.name} key={category._id} />
                            ))
                        }

                    </div>
                </div>
            </div>

            
        </>
    )
}

export default Category