import React from 'react'
import { Link } from 'react-router-dom'

const ProductBannerItem = () => {
    return (
        <>
            <div className="col-md-6">
                <div className="banner-product-single-item">
                    <div className="thumb">
                        <Link to="/shop">
                            <img src="assets/img/shop/banner/1.webp" width="570" height="300" alt="HasTech" />
                        </Link>
                    </div>
                    <div className="content">
                        <h5 className="sub-title">50% Off</h5>
                        <h5 className="title">Dog Food</h5>
                        <Link className="btn-theme btn-theme-color btn-sm" to="/shop">Shop Now</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductBannerItem