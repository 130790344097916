const cart = {
    cartItems: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [],
    shippingInfo: localStorage.getItem('shippingInfo') ? JSON.parse(localStorage.getItem('shippingInfo')) : {},
    paymentInfo: localStorage.getItem('paymentInfo') ? JSON.parse(localStorage.getItem('paymentInfo')) : {},
    orderInfo: localStorage.getItem('orderInfo') ? JSON.parse(localStorage.getItem('orderInfo')) : {},
    error: null
}

const Cart = (state = cart, action) => {
    switch (action.type) {
        case "ADD_TO_CART":
            return {
                ...state,
                cartItems: action.payload
            }
        case "REMOVE_CART_ITEM":
            return {
                ...state,
                cartItems: action.payload
            }
        case "SAVE_SHIPPING_INFO":
            return {
                ...state,
                shippingInfo: action.payload
            }
        case "SAVE_PAYMENT_INFO":
            return {
                ...state,
                paymentInfo: action.payload
            }
        case "SAVE_ORDER_INFO":
            return {
                ...state,
                orderInfo: action.payload
            }
        case "CLEAR_CART":
            return {
                ...state,
                cartItems: [],
                shippingInfo: {},
                paymentInfo: {},
                orderInfo: {}
            }
        case "CART_ERROR":
            return {
                ...state,
                error: action.payload
            }

        case "MINUS_CART_ITEM_QTY":
            return {
                ...state,
                cartItems: action.payload
            }
        case "PLUS_CART_ITEM_QTY":
            return {
                ...state,
                cartItems: action.payload
            }
            case "CLEAR_CART":
                return {
                    ...state,
                    cartItems: [],
                    shippingInfo: {},
                    paymentInfo: {},
                    orderInfo: {}
                }
        default:
            return state
    }
}

export default Cart