import React from 'react'
import { Link } from 'react-router-dom'

const Deals = () => {
    return (
        <>
            <section className="bg-theme-color position-relative z-index-1 csb-5">
                <div className="container-fluid p--0">
                    <div className="row divider-style1">
                        <div className="col-lg-3 col-xl-4">
                            <div className="divider-thumb divider-thumb-left">
                                <img src="assets/img/photos/divider1.webp" width="351" height="435" alt="HasTech" />
                                <div className="shape-circle"></div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-4">
                            <div className="divider-content text-center">
                                <h5 className="sub-title">Save 50% Off</h5>
                                <h2 className="title">Best Deal Offer</h2>
                                <p className="desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore etlop.</p>
                                <Link className="btn-theme text-dark" to="/shop">Shop Now</Link>
                                <img className="shape-object" src="assets/img/shape/object1.webp" width="316" height="302" alt="HasTech" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-xl-4">
                            <div className="divider-thumb divider-thumb-right">
                                <img src="assets/img/photos/divider2.webp" width="488" height="447" alt="HasTech" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Deals