import React from 'react'

const FeatureArea = () => {
  return (
    <>
        <div className="feature-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="feature-icon-box">
              <div className="icon-box">
                <img className="icon-img" src="assets/img/icons/f1.webp" width="46" height="34" alt="HasTech"/>
              </div>
              <div className="content">
                <h5 className="title">Free Shipping</h5>
                <p>Capped at $39 per order</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="feature-icon-box">
              <div className="icon-box">
                <img className="icon-img" src="assets/img/icons/f2.webp" width="43" height="34" alt="HasTech"/>
              </div>
              <div className="content">
                <h5 className="title">Card Payments</h5>
                <p>12 Months Installments</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="feature-icon-box">
              <div className="icon-box">
                <img className="icon-img" src="assets/img/icons/f3.webp" width="39" height="39" alt="HasTech"/>
              </div>
              <div className="content">
                <h5 className="title">Easy Returns</h5>
                <p>Shop With Confidence</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="feature-icon-box">
              <div className="icon-box">
                <img className="icon-img" src="assets/img/icons/f4.webp" width="36" height="39" alt="HasTech"/>
              </div>
              <div className="content">
                <h5 className="title">24/7 Support</h5>
                <p>Contact 24 hours everyday</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default FeatureArea