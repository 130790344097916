import axios from "axios";
const host = process.env.REACT_APP_API_HOST || "http://localhost:5000";

export const getCategories = () => async (dispatch) => {
    try {
        dispatch({ type: "CATEGORY_LIST_REQUEST" });

        const { data } = await axios.get(`${host}/category`);
        dispatch({
            type: "CATEGORY_LIST_SUCCESS",
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: "CATEGORY_LIST_FAIL",
            payload:    error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
}