const modalState = {
    data : null,
}

const Modal = (state = modalState, action) => {
    switch (action.type) {
        case "SET_MODAL":
            return { data: action.payload }
        case "CLEAR_MODAL":
            return { data: null }
        default:
            return state
    }
}

export default Modal