import React from 'react'

const TeamItem = () => {
    return (
        <>
            <div className="col-sm-6 col-lg-3">
                <div className="team-item">
                    <div className="thumb">
                        <img src="assets/img/team/1.webp" width="270" height="300" alt="HasTech" />
                        <div className="member-icons">
                            <a href="https://www.facebook.com/" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a>
                            <a href="https://dribbble.com/" target="_blank" rel="noreferrer"><i className="fa fa-dribbble"></i></a>
                            <a href="https://www.pinterest.com/" target="_blank" rel="noreferrer"><i className="fa fa-pinterest-p"></i></a>
                            <a href="https://twitter.com/" target="_blank" rel="noreferrer"><i className="fa fa-twitter"></i></a>
                        </div>
                    </div>
                    <div className="content">
                        <h4 className="title">Dianna Smiley</h4>
                        <p>Team Member</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TeamItem