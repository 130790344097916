import React from 'react'
import PageHeadre from '../components/Product/PageHeadre'

const Contact = () => {
  return (
    <>
        <PageHeadre title="Contact"/>
        <section className="contact-area contact-inner-area">
      <div className="container">
        <div className="row contact-page-wrapper">
          <div className="col-xl-5">
            <h4 className="contact-page-title">We Are Here! <br/>Please Contact Us.</h4>
            <div className="contact-info-wrap">
              <div className="contact-info">
                <div className="row">
                  <div className="col-12 col-lg-6 col-xl-12">
                    <div className="info-item">
                      <div className="icon">
                        <img src="assets/img/icons/c1.webp" alt="HasTech"/>
                      </div>
                      <div className="info">
                        <h5 className="title">Address:</h5>
                        <p>Your address goes here.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-12">
                    <div className="info-item">
                      <div className="icon">
                        <img src="assets/img/icons/c2.webp" alt="HasTech"/>
                      </div>
                      <div className="info">
                        <h5 className="title">Phone:</h5>
                        <p>
                          <a href="tel://+00123456789">+00 123 456 789</a><br/>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-12">
                    <div className="info-item">
                      <div className="icon">
                        <img src="assets/img/icons/c3.webp" alt="HasTech"/>
                      </div>
                      <div className="info">
                        <h5 className="title">Email:</h5>
                        <p>
                          <a href="mailto://demo@example.com">demo@example.com</a><br/>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-7">
            <h4 className="contact-page-title page-title-style2">Send A Quest</h4>
            <div className="contact-form-wrap">
              <div className="contact-form">
                <form id="contact-form" action="/" method="POST">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input className="form-control" type="text" name="con_name" placeholder="Name *"/>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input className="form-control" type="email" name="con_email" placeholder="Email *"/>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input className="form-control" type="text" placeholder="Subject (Optinal)"/>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group mb--0">
                        <textarea className="form-control" name="con_message" placeholder="Message"></textarea>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group mb--0">
                        <button className="btn-theme" type="submit">Send Message</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Contact