import {React, useEffect} from 'react'
// import PageHeadre from '../components/Product/PageHeadre'
import ProductDetail from '../components/Product/ProductDetail'
import ProductSuggestions from '../components/Product/ProductSuggestions'

const SingleProduct = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      })
    return (
        <>
            {/* <PageHeadre/> */}
            <ProductDetail/>
            <ProductSuggestions/>
        </>
    )
}

export default SingleProduct