import { React, useEffect } from 'react'
import AboutBestProduct from '../components/Home/AboutBestProduct'
// import BlogContainer from '../components/Home/BlogContainer'
import Category from '../components/Home/Category'
// import DailyProducts from '../components/Home/DailyProducts'
import Deals from '../components/Home/Deals'
import Hero from '../components/Home/Hero'
import ProductBanner from '../components/Home/ProductBanner'
import Products from '../components/Home/Products'

import { useSelector } from 'react-redux'


const Home = () => {
  const Product = useSelector(state => state.Product)
  const Categories = useSelector(state => state.Category)
  const newCollection = Product.products.slice(0,4);
  const FeaturedProducts = Product.products.slice(2,6)
  // add any 4 from Product.products in deals
  const DealsProducts = Product.products.slice(3,6)

  useEffect(() => {
    window.scrollTo(0, 0);
  })
  return (
    <>
      <main className="main-content">
        <Hero />
        <Category data={Categories} />
        <ProductBanner />
        <Products data={newCollection} sectionTitle="Best Product" sectionSubtitle="New Collection"  />
        <Deals />
        {/* <DailyProducts/> */}
        <Products data={FeaturedProducts} sectionTitle="Best Product" sectionSubtitle="Featured Products"  />
        <AboutBestProduct />
        <Products data={DealsProducts} sectionTitle="Best Product" sectionSubtitle="Deals of the Day"  />
        {/* <BlogContainer /> */}

      </main>
    </>
  )
}

export default Home