import React from 'react'

const TestimonialItem = () => {
  return (
    <>
        <div className="col-lg-4">
            <div className="testimonial-item">
              <div className="testi-inner-content">
                <div className="testi-author">
                  <div className="testi-thumb">
                    <img src="assets/img/testimonial/1.webp" width="90" height="90" alt="HasTech"/>
                  </div>
                  <div className="testi-info">
                    <h4 className="title">Dianna Smiley</h4>
                    <p className="sub-title">Client</p>
                    <div className="rating-box">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </div>
                  </div>
                </div>
                <div className="testi-content">
                  <p className="desc">Lorem ipsum dolor sit amet consectetl adipisicing elit, sed do eiusmod tempo incidi ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
                  <div className="testi-quote"><img src="assets/img/icons/quote1.webp" width="55" height="38" alt="HasTech"/></div>
                </div>
              </div>
            </div>
          </div>
    </>
  )
}

export default TestimonialItem