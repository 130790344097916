import axios from 'axios'
const host = process.env.REACT_APP_API_HOST || 'http://localhost:5000';
export const listProducts = () => async (dispatch) => {
    try {
        dispatch({ type: 'PRODUCT_LIST_REQUEST' })

        const { data } = await axios.get(`${host}/products`)

        dispatch({
            type: 'PRODUCT_LIST_SUCCESS',
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: 'PRODUCT_LIST_FAIL',
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}


// export const listProductDetails = (id) => async (dispatch) => {
//     try {
//         dispatch({ type: PRODUCT_DETAILS_REQUEST })

//         const { data } = await axios.get(`/products/${id}`)

//         dispatch({
//             type: PRODUCT_DETAILS_SUCCESS,
//             payload: data,
//         })
//     } catch (error) {
//         dispatch({
//             type: PRODUCT_DETAILS_FAIL,
//             payload:
//                 error.response && error.response.data.message
//                     ? error.response.data.message
//                     : error.message,
//         })
//     }
// }

