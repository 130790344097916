import React from 'react'
import { Link } from 'react-router-dom'

const MenuAside = () => {
  return (
    <>
        <aside className="off-canvas-wrapper offcanvas offcanvas-start" tabIndex="-1" id="AsideOffcanvasMenu" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <h1 className="d-none" id="offcanvasExampleLabel">Aside Menu</h1>
                    <button className="btn-menu-close" data-bs-dismiss="offcanvas" aria-label="Close">menu <i className="fa fa-chevron-left"></i></button>
                </div>
                <div className="offcanvas-body">
                    <nav id="offcanvasNav" className="offcanvas-menu-nav">
                        <ul>
                            <li className="offcanvas-nav-parent" data-bs-toggle="offcanvas" data-bs-target="#AsideOffcanvasMenu" aria-controls="AsideOffcanvasMenu"><Link className="offcanvas-nav-item" to="/">Home</Link></li>
                            <li className="offcanvas-nav-parent" data-bs-toggle="offcanvas" data-bs-target="#AsideOffcanvasMenu" aria-controls="AsideOffcanvasMenu"><Link className="offcanvas-nav-item" to="/about">About</Link></li>
                            <li className="offcanvas-nav-parent" data-bs-toggle="offcanvas" data-bs-target="#AsideOffcanvasMenu" aria-controls="AsideOffcanvasMenu"><Link className="offcanvas-nav-item" to="/shop">Shop</Link></li>
                            <li className="offcanvas-nav-parent" data-bs-toggle="offcanvas" data-bs-target="#AsideOffcanvasMenu" aria-controls="AsideOffcanvasMenu"><Link className="offcanvas-nav-item" to="/conatct">Contact</Link></li>
                        </ul>
                    </nav>
                </div>
            </aside>
    </>
  )
}

export default MenuAside