import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
    return (
        <>
            <footer className="footer-area">
                <div className="footer-main">
                    <div className="container pt--0 pb--0">
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <div className="widget-item widget-about">
                                    <h4 className="widget-title">About Us</h4>
                                    <p className="desc">Lorem ipsum dolor sit amet, consectel adipisicing elit, sed do eiusmod temp incidid ut labore et dolo</p>
                                    <div className="social-icons">
                                        <a href="https://www.facebook.com/" rel="noreferrer" target="_blank"><i className="fa fa-facebook"></i></a>
                                        <a href="https://dribbble.com/" rel="noreferrer" target="_blank"><i className="fa fa-dribbble"></i></a>
                                        <a href="https://www.pinterest.com/" rel="noreferrer" target="_blank"><i className="fa fa-pinterest-p"></i></a>
                                        <a href="https://twitter.com/" rel="noreferrer" target="_blank"><i className="fa fa-twitter"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="widget-item nav-menu-item1">
                                    <h4 className="widget-title">Information</h4>
                                    <h4 className="widget-collapsed-title collapsed" data-bs-toggle="collapse" data-bs-target="#widgetId-1">Information</h4>
                                    <div id="widgetId-1" className="collapse widget-collapse-body">
                                        <div className="collapse-body">
                                            <div className="widget-menu-wrap">
                                                <ul className="nav-menu">
                                                    <li><Link to="/shop">About Us</Link></li>
                                                    <li><a href="account-login.html">Delivery Information</a></li>
                                                    <li><a href="account-login.html">Privacy Policy</a></li>
                                                    <li><a href="account-login.html">Terms & Conditions</a></li>
                                                    <li><a href="contact.html">Contact Us</a></li>
                                                    <li><a href="account-login.html">Loag In Info</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="widget-item nav-menu-item2">
                                    <h4 className="widget-title">Our Policy</h4>
                                    <h4 className="widget-collapsed-title collapsed" data-bs-toggle="collapse" data-bs-target="#widgetId-2">Our Policy</h4>
                                    <div id="widgetId-2" className="collapse widget-collapse-body">
                                        <div className="collapse-body">
                                            <div className="widget-menu-wrap">
                                                <ul className="nav-menu">
                                                    <li><a href="account-login.html">Gallery</a></li>
                                                    <li><a href="shop.html">Brands</a></li>
                                                    <li><a href="account-login.html">Gift Certificates</a></li>
                                                    <li><a href="shop.html">Specials</a></li>
                                                    <li><a href="account.html">My Account Us</a></li>
                                                    <li><a href="about-us.html">About Us</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="widget-item">
                                    <h4 className="widget-title">Contact Info:</h4>
                                    <h4 className="widget-collapsed-title collapsed" data-bs-toggle="collapse" data-bs-target="#widgetId-3">Contact Info:</h4>
                                    <div id="widgetId-3" className="collapse widget-collapse-body">
                                        <div className="collapse-body">
                                            <div className="widget-contact-info">
                                                <p className="contact-info-desc">If you have any question.please contact us at <a href="mailto://demo@example.com">demo@example.com</a></p>
                                                <div className="contact-item">
                                                    <div className="icon">
                                                        <i className="pe-7s-map-marker"></i>
                                                    </div>
                                                    <div className="info">
                                                        <p>Your address goes here. <br />123, Address.</p>
                                                    </div>
                                                </div>
                                                <div className="contact-item phone-info">
                                                    <div className="icon">
                                                        <i className="pe-7s-phone"></i>
                                                    </div>
                                                    <div className="info">
                                                        <p><span>Have any Question</span> <br /><a href="tel://0123456789">+0 123 456 789</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="container pt--0 pb--0">
                        <div className="row">
                            <div className="col-12">
                                <div className="footer-bottom-content">
                                    <p className="copyright">© 2021 Pet App Made with <i className="fa fa-heart"></i> by <a rel="noreferrer" target="_blank" href="https://techfye.com">Techfye.</a></p>
                                    <div className="payment">
                                        <a href="account.html"><img src="assets/img/photos/payment.webp" width="192" height="21" alt="Payment Logo" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer