import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
const Hero = () => {
    return (
        <>
            <section className="home-slider-area">
                <div className="swiper home-slider-container default-slider-container">
                    <div className="swiper-wrapper home-slider-wrapper slider-default"  >
                        <Swiper 
                            style={{height: '100%', width: '90%'}}
                        >
                            <SwiperSlide>
                                <div className="slider-content-area" >
                                    <div className="container">
                                        <div className="slider-container">
                                            <div className="row justify-content-between align-items-center">
                                                <div className="col-sm-6 col-md-6">
                                                    <div className="slider-content">
                                                        <div className="content">
                                                            <div className="sub-title-box">
                                                                <h5 className="sub-title">Up To 40% Off</h5>
                                                            </div>
                                                            <div className="title-box">
                                                                <h2 className="title">A Greate Meal With Your Pet</h2>
                                                            </div>
                                                            <div className="btn-box">
                                                                <Link className="btn-theme text-dark" to="/products">Shop Now</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-6">
                                                    <div className="slider-thumb mousemove">
                                                        <div className="thumb">
                                                            <img src="/assets/img/slider/slider-01.png" width="585" height="579" alt='something' />
                                                            <div className="shape-one"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                        </Swiper>
                        {/* <div className="swiper-slide">
                            <div className="slider-content-area" style={{ backgroundImage : `url(/assets/img/slider/slider-bg.png)` }}>
                                <div className="container">
                                    <div className="slider-container">
                                        <div className="row justify-content-between align-items-center">
                                            <div className="col-sm-6 col-md-6">
                                                <div className="slider-content">
                                                    <div className="content">
                                                        <div className="sub-title-box">
                                                            <h5 className="sub-title">Up To 40% Off</h5>
                                                        </div>
                                                        <div className="title-box">
                                                            <h2 className="title">A Greate Meal With Your Pet</h2>
                                                        </div>
                                                        <div className="btn-box">
                                                            <a className="btn-theme text-dark" href="shop.html">Shop Now</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="slider-thumb mousemove">
                                                    <div className="thumb">
                                                        <img src="/assets/img/slider/slider-01.png" width="585" height="579" alt='something' />
                                                        <div className="shape-one"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="slider-content-two-area" data-bg-img="assets/img/slider/slider-02.webp">
                                <div className="container">
                                    <div className="slider-container">
                                        <div className="row justify-content-sm-end justify-content-center align-items-center">
                                            <div className="col-sm-8 col-md-6">
                                                <div className="slider-content">
                                                    <div className="content">
                                                        <div className="sub-title-box">
                                                            <h5 className="sub-title">100% Genue Products</h5>
                                                        </div>
                                                        <div className="title-box">
                                                            <h2 className="title">This Food Best Your Pet</h2>
                                                        </div>
                                                        <div className="desc-box">
                                                            <p className="desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, seddo do eiusmod tempor incidid ut labore.</p>
                                                        </div>
                                                        <div className="btn-box">
                                                            <a className="btn-theme text-dark" href="shop.html">Shop Now</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="home-overlay"></div>
                            </div>
                        </div> */}
                    </div>

                    <div className="swiper-btn-wrap">
                        <div className="swiper-btn-prev">
                            <i className="pe-7s-angle-left"></i>
                        </div>
                        <div className="swiper-btn-next">
                            <i className="pe-7s-angle-right"></i>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Hero