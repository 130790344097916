export const AddtoCart = (product, qty) => async (dispatch) => {
    const cartItems = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];
    const item = cartItems.find(item => item.product === product._id);
    if (item) {
        cartItems.forEach(item => {
            if (item.product === product._id) {
                item.qty = Number(item.qty) + Number(qty);
            }
        })
    } else {
        cartItems.push({
            product: product._id,
            name: product.name,
            price: product.price,
            image: product.images[0],
            stock: product.stock,
            qty: qty
        })
    }
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    dispatch({
        type: "ADD_TO_CART",
        payload: cartItems
    })
}

export const RemoveCartItem = (id) => async (dispatch) => {
    const cartItems = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];
    cartItems.forEach((item, index) => {
        if (item.product === id) {
            cartItems.splice(index, 1);
        }
    })
    localStorage.setItem('cartItems', JSON.stringify(cartItems));


    dispatch({
        type: "REMOVE_CART_ITEM",
        payload: cartItems
    })
}

export const MinusCartItemQty = (id) => async (dispatch) => {
    const cartItems = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];
    cartItems.forEach((item, index) => {
        if (item.product === id) {
            if (item.qty > 1) {
                item.qty = Number(item.qty) - 1;
            }
        }
    })
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    dispatch({
        type: "MINUS_CART_ITEM_QTY",
        payload: cartItems
    })
}

export const PlusCartItemQty = (id) => async (dispatch) => {
    const cartItems = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];
    cartItems.forEach((item, index) => {
        if (item.product === id) {
            item.qty = Number(item.qty) + 1;
        }
    })
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    dispatch({
        type: "PLUS_CART_ITEM_QTY",
        payload: cartItems
    })
}

export const SaveShippingInfo = (data) => async (dispatch) => {
    dispatch({
        type: "SAVE_SHIPPING_INFO",
        payload: data
    })
}

export const SavePaymentInfo = (data) => async (dispatch) => {
    dispatch({
        type: "SAVE_PAYMENT_INFO",
        payload: data
    })
}

export const SaveOrderInfo = (data) => async (dispatch) => {
    dispatch({
        type: "SAVE_ORDER_INFO",
        payload: data
    })
}

export const ClearCart = () => async (dispatch) => {
    localStorage.removeItem('cartItems');
    dispatch({
        type: "CLEAR_CART",
        payload: []
    })
}

