import { React, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { listProducts } from '../actions/productAction'
import { getCategories } from '../actions/categoryAction'
import { useDispatch, useSelector } from 'react-redux'
const Header = () => {
    const dispatch = useDispatch()
    const Cart = useSelector(state => state.Cart)
    const { cartItems } = Cart

    const getCartCount = () => {
        return cartItems.reduce((qty, item) => Number(item.qty) + qty, 0)
    }

    useEffect(() => {
        dispatch(listProducts())
        dispatch(getCategories())
    }, [dispatch])

    return (
        <>
            <header className="header-area" >
                <div className="container">
                    <div className="row no-gutter align-items-center position-relative">
                        <div className="col-12">
                            <div className="header-align mobile-header">
                                <div className='mobile-side-menu'>
                                <div className="mobile-menu">
                                    <div className="header-action-area">
                                        <button className="btn-menu" type="button" data-bs-toggle="offcanvas" data-bs-target="#AsideOffcanvasMenu" aria-controls="AsideOffcanvasMenu">
                                            <i className="pe-7s-menu"></i>
                                        </button>
                                    </div>
                                    <div className="header-action-area">
                                        <div className="header-logo-area">
                                            <Link to="/">
                                                <img className="logo-main" src="assets/img/logo-light.svg" width="158" height="80" alt="Logo" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="header-action-area">
                                    <div className="shopping-account px-1">
                                            <Link className="shopping-account-btn" to="/login">
                                                <i className="pe-7s-users icon"></i>
                                            </Link>
                                        </div>
                                        <div className="shopping-cart">
                                            <button className="shopping-cart-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#AsideOffcanvasCart" aria-controls="AsideOffcanvasCart">
                                                <i className="pe-7s-shopbag icon"></i>
                                                <sup className="shop-count">{getCartCount()}</sup>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="header-align-start">
                                    <div className="header-logo-area">
                                        <Link to="/">
                                            <img className="logo-main" src="assets/img/logo-light.svg" width="158" height="80" alt="Logo" />
                                        </Link>
                                    </div>
                                    
                                </div>
                                <div className="header-align-center">
                                    <div className="header-navigation-area position-relative">
                                        <ul className="main-menu nav">
                                            <li className="has-submenu"><Link to="/"><span>Home</span></Link>
                                                {/* <ul className="submenu-nav">
                                                    <li><a href="index.html"><span>Home One</span></a></li>
                                                    <li><a href="index-two.html"><span>Home Two</span></a></li>
                                                </ul> */}
                                            </li>
                                            <li><Link to="/about"><span>About</span></Link></li>
                                            <li className="has-submenu position-static"><Link to="/shop"><span>Shop</span></Link>
                                                {/* <ul className="submenu-nav submenu-nav-mega column-3">
                                                    <li className="mega-menu-item"><a href="#/" className="mega-title"><span>Shop Layout</span></a>
                                                        <ul>
                                                            <li><a href="shop-three-columns.html"><span>Shop 3 Column</span></a></li>
                                                            <li><a href="shop-four-columns.html"><span>Shop 4 Column</span></a></li>
                                                            <li><a href="shop-left-sidebar.html"><span>Shop Left Sidebar</span></a></li>
                                                            <li><a href="shop.html"><span>Shop Right Sidebar</span></a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="mega-menu-item"><a href="#/" className="mega-title"><span>Single Product</span></a>
                                                        <ul>
                                                            <li><a href="single-normal-product.html"><span>Single Product Normal</span></a></li>
                                                            <li><a href="single-product.html"><span>Single Product Variable</span></a></li>
                                                            <li><a href="single-group-product.html"><span>Single Product Group</span></a></li>
                                                            <li><a href="single-affiliate-product.html"><span>Single Product Affiliate</span></a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="mega-menu-item"><a href="#/" className="mega-title"><span>Others Pages</span></a>
                                                        <ul>
                                                            <li><a href="shop-cart.html"><span>Shopping Cart</span></a></li>
                                                            <li><a href="shop-checkout.html"><span>Checkout</span></a></li>
                                                            <li><a href="shop-wishlist.html"><span>Wishlist</span></a></li>
                                                            <li><a href="shop-compare.html"><span>Compare</span></a></li>
                                                        </ul>
                                                    </li>
                                                </ul> */}
                                            </li>
                                            {/* <li className="has-submenu"><a href="#/"><span>Blog</span></a>
                                                <ul className="submenu-nav submenu-nav-mega">
                                                    <li className="mega-menu-item"><a href="#/" className="mega-title">Blog Layout</a>
                                                        <ul>
                                                            <li><a href="blog-grid.html">Blog Grid</a></li>
                                                            <li><a href="blog.html">Blog Left Sidebar</a></li>
                                                            <li><a href="blog-right-sidebar.html">Blog Right Sidebar</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="mega-menu-item"><a href="#/" className="mega-title">Single Blog</a>
                                                        <ul>
                                                            <li><a href="blog-details-no-sidebar.html">Blog Details</a></li>
                                                            <li><a href="blog-details.html">Blog Details Left Sidebar</a></li>
                                                            <li><a href="blog-details-right-sidebar.html">Blog Details Right Sidebar</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li> */}
                                            {/* <li className="has-submenu"><a href="#/"><span>Pages</span></a>
                                                <ul className="submenu-nav">
                                                    <li><Link to="account.html"><span>Account</span></Link></li>
                                                    <li><Link to="/login"><span>Login</span></Link></li>
                                                    <li><Link to="/register"><span>Register</span></Link></li>
                                                </ul>
                                            </li> */}
                                            <li><Link to="/contact"><span>Contact</span></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="header-align-end">
                                    <div className="header-action-area">
                                        <div className="shopping-search">
                                            <button className="shopping-search-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#AsideOffcanvasSearch" aria-controls="AsideOffcanvasSearch"><i className="pe-7s-search icon"></i></button>
                                        </div>
                                        <div className="shopping-account">
                                            <Link className="shopping-account-btn" to="/login">
                                                <i className="pe-7s-users icon"></i>
                                            </Link>
                                        </div>
                                        <div className="shopping-wishlist">
                                            <Link className="shopping-wishlist-btn" to="/wishlist">
                                                <i className="pe-7s-like icon"></i>
                                            </Link>
                                        </div>
                                        <div className="shopping-cart">
                                            <button className="shopping-cart-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#AsideOffcanvasCart" aria-controls="AsideOffcanvasCart">
                                                <i className="pe-7s-shopbag icon"></i>
                                                <sup className="shop-count">{getCartCount()}</sup>
                                            </button>
                                        </div>
                                        <button className="btn-menu" type="button" data-bs-toggle="offcanvas" data-bs-target="#AsideOffcanvasMenu" aria-controls="AsideOffcanvasMenu">
                                            <i className="pe-7s-menu"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header