import React from 'react'
import { Link } from 'react-router-dom'

const PageHeadre = (props) => {
    return (
        <>
            <div className="page-header-area" style={{ backgroundImage: `url(/assets/img/photos/bg1.webp)` }}>
                <div className="container pt--0 pb--0">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-header-content">
                                <h2 className="title">{props.title}</h2>
                                <nav className="breadcrumb-area">
                                    <ul className="breadcrumb">
                                        <li><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-sep">-&gt;</li>
                                        <li>{props.title}</li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageHeadre