import React from 'react'
import ProductItem from './ProductItem'

const ProductSuggestions = () => {
  return (
    <>
        <section className="product-area product-default-area">
                <div className="container pt--0">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title shape-center text-center">
                                <h5 className="sub-title">Best PRODUCT</h5>
                                <h2 className="title">Related Products</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ProductItem/>
                        <ProductItem/>
                        <ProductItem/>
                        <ProductItem/>
                    </div>
                </div>
            </section>
    </>
  )
}

export default ProductSuggestions