const CategoryState = {
    categories: [],
    loading: false,
    error: null
}

const Category = (state = CategoryState, action) => {
    switch (action.type) {
        case "CATEGORY_LIST_REQUEST":
            return { loading: true, categories: [] }
        case "CATEGORY_LIST_SUCCESS":
            return { loading: false, categories: action.payload }
        case "CATEGORY_LIST_FAIL":
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export default Category